.nav-right{
  list-style: none;
  overflow-y: hidden;
}

.nav-right li{
  overflow: hidden;
}

/* .options_container > li:first-child{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.options_container> li:last-child{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
} */

.options_container{
  overflow: hidden;
  align-items: center;
}