.value{
  font-weight: 900;
font-size: 30px;
line-height: 40px;
}
p {
  font-weight: 600;
font-size: 14px;
line-height: 22px;


color: #1A3353;
}